import s from "./NewsPages.module.css"
import img from "../../assets/News/News080425-1.jpg"
import img2 from "../../assets/News/News080425-2.jpg"
import img3 from "../../assets/News/News080425-3.jpg"
import img4 from "../../assets/News/News080425-4.jpg"
import {AdviceBlock, ShareBlock} from "./News170621";

const News080425 = () => {

    return (
        <div className={s.main}>
            <h1>
            Завершил работу XIII Всероссийский съезд руководителей организаций для детей-сирот и детей, оставшихся без попечения родителей, и руководителей органов опеки и попечительства
            </h1>
            <div className={s.date}>
                3-4 апреля 2025
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img4} alt=""/>
                </div>
                <div>
                3-4 апреля 2025 г. в Москве прошел XIII Всероссийский съезд руководителей организаций для детей-сирот и детей, оставшихся без попечения родителей, и руководителей органов опеки и попечительства.

                    <p></p>
                    На мероприятии были представлены лекции по актуальным вопросам, связанным с защитой прав детей, организована работа площадок малого пленума, посвященных, в том числе вопросам совместной работы организаций для детей-сирот и семьи в интересах ребенка, профессиональной помощи, направленной на укрепление детско-родительских отношений, создания безопасной среды для подростков.

                    <p></p>
                    Значимыми событиями Всероссийского съезда стали стратегическая сессия с фокусом на перспективу развития организаций для детей-сирот и органов опеки и попечительства на основе анализа существующих вызовов и поставленных целей развития страны, сформулированных в государственных стратегических документах, а также заседание Экспертного совета Минпросвещения России по вопросам опеки и попечительства в отношении несовершеннолетних граждан.
                    <div className={s.newsImg}>
                    <img src={img2} alt=""/>
                </div>
                <div className={s.newsImg}>
                    <img src={img3} alt=""/>
                </div>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <p></p>
                В рамках Всероссийского съезда  состоялась церемония  торжественного  вручения благодарственных писем Минпросвещения России коллективам организаций для детей-сирот, в связи с юбилейными датами со дня их основания, которые отличает профессионализм, единство, высокие показатели при реализации основных положений государственной семейной политики, богатые и ценные традиции.
                <p>
                    <a href="https://disk.yandex.ru/d/9Q07xjhInoOAmw">
                    Материалы XIII Всероссийского съезда руководителей организаций для детей-сирот и детей, оставшихся без попечения родителей, и руководителей органов опеки и попечительства

                    </a>
                </p>
                </div>
                
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News080425
